import React from "react"

import { Provider } from "react-redux"
import store from "./src/redux/stores/configureStore"

import { CartProvider } from "use-shopping-cart"

// eslint-disable-next-line react/display-name,react/prop-types
const appProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <CartProvider
        mode="payment"
        cartMode="checkout-session"
        stripe={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}
        successUrl={process.env.GATSBY_STRIPE_SUCCESS_URL}
        cancelUrl={process.env.GATSBY_STRIPE_CANCEL_URL}
        currency="NZD"
        allowedCountries={["NZ"]}
        billingAddressCollection={true}
      >
        {element}
      </CartProvider>
    </Provider>
  )
}

export default appProvider
