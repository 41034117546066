export const loadState = (item) => {
  try {
    const serialiazedState = localStorage.getItem(item);
    if (serialiazedState === null) {
      return undefined;
    }
    return JSON.parse(serialiazedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = (state, item) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(item, serializedState);
  } catch (e) {
    console.log(e);
    //ignore error
  }
};
