import { createSlice } from "@reduxjs/toolkit"

import { loadState, saveState } from "utils/localStorage"

const persistedState = loadState("mode")

const initialStateValue = {
  mode: persistedState === undefined ? "light" : persistedState,
}

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setAppState: (state, action) => {
      state.value = action.payload
      saveState(state.value.mode, "mode")
    },
  },
})
export const { setAppState } = appStateSlice.actions

export default appStateSlice.reducer
