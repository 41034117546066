exports.components = {
  "component---src-components-templates-single-product-js": () => import("./../../../src/components/templates/singleProduct.js" /* webpackChunkName: "component---src-components-templates-single-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-life-cycle-of-a-darkling-beetle-mealworm-js": () => import("./../../../src/pages/blog/life-cycle-of-a-darkling-beetle-mealworm.js" /* webpackChunkName: "component---src-pages-blog-life-cycle-of-a-darkling-beetle-mealworm-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

