import { configureStore, combineReducers } from "@reduxjs/toolkit"

import createSagaMiddleware from "redux-saga"

import rootSaga from "redux/sagas/rootSagas"

//Reducers
import appReducer from "redux/slices/appSlice"

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
  appState: appReducer,
})

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export default store
